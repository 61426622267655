<template>
    <div class="relative m-0 p-0 w-full">
        <!-- odkomentuj img aby porównać do oryginału -->
        <!-- <img class="absolute top-0 right-0 w-full opacity-20" src="images/diagnoza_propo_v2_specjalisci.jpeg"></img> -->
        <Header/>
        <!-- person section -->
        <div class="container mx-auto mt-4 lg:mt-14 lg:mb-10 lg:px-24">
            <div class="flex flex-col xl:flex-row justify-between">
                <div class="w-full xl:w-2/5 xl:pr-12">
                    <!-- poziome test -->
                    <img class="lg:w-1/2 mx-auto h-auto shadow-lg xl:rounded-3xl xl:object-cover xl:h-96 xl:w-full"
                         :src="(service.image && service.image.path) || require('../assets/images/usg1.jpg')" alt="no image" rel="preload">
                    <!-- pionowe test -->
                    <!-- <img class="w-1/2 mx-auto h-auto shadow-lg xl:rounded-3xl xl:object-cover xl:h-96 xl:w-full" src="images/person2.jpeg" alt="no image"></img> -->

                </div>
                <div class="mt-2 w-full pb-3 px-3 font-bold text-xs lg:text-xl lg:leading-7">
                    <p class="text-base mt-2 mb-3 text-left lg:text-2xl underline pb-4">{{ service.name }}</p>
                    <p class="text-left mx-2" style="white-space: pre-wrap" v-html="service.properText"></p>
                </div>
            </div>
        </div>
        <div class="container mx-auto mt-4 mb-5 lg:mt-10 lg:mb-10 lg:px-24 lg:h-82">
            <div class="flex flex-col items-center">
<!--                <div class="uppercase text-xl lg:text-2xl font-bold pt-6 lg:pb-10 pb-4">usługi:</div>-->
<!--                    <p class="pb-4 font-bold lg:text-xl" v-for="(service,index) in services" :key="index">{{ service.name }}</p>-->

                <button class="btn-mydr-pp" data-doctor="" data-appname="drw"
                        data-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmYWNpbGl0eV9pZCI6ODkyMX0.p-pzAyxJJdAzhV3ud8mChAuLwN21JolyPcVudZsMAK0">
                </button>
                <!--                <button class="mt-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105-->
<!--            lg:text-3xl bg-green-500 hover:bg-green-700 text-white lg:py-8 lg:px-40 rounded-2xl lg:rounded-3xl mb-12 lg:mb-0 px-6 py-3">-->
<!--                    Umów wizytę-->
<!--                </button>-->
            </div>
            <div>

            </div>
        </div>
        <!-- info section -->
        <!-- offer section -->
        <div id="offer" class="bg-gray-200 w-full lg:mt-12">
            <div class="container mx-auto px-3 lg:px-24 lg:pb-8">
                <div class="pt-4 lg:pt-10">
                    <div class="grid pt-6 lg:pt-0 gap-4 grid-flow-row grid-cols-4 grid-rows-1 lg:gap-12">
                        <img class="w-auto lg:rounded-3xl " src="../assets/images/usg1.jpg" alt="no image" rel="preload">
                        <img class="w-auto lg:rounded-3xl" src="../assets/images/viofor.jpg" alt="no image" rel="preload">
                        <img class="w-auto lg:rounded-3xl" src="../assets/images/viofor.jpg" alt="no image" rel="preload">
                        <img class="w-auto lg:rounded-3xl " src="../assets/images/usg1.jpg" alt="no image" rel="preload">
                    </div>
                </div>
            </div>
        </div>
        <!-- offer section -->
        <DoctorList/>
        <Contact/>
        <Footer/>
    </div>
</template>
<script>
    import axios from 'axios';
    import Header from "../components/Header";
    import Contact from "../components/Contact";
    import Footer from "../components/Footer";
    import DoctorList from "../components/DoctorList";
    export default {
        components: {DoctorList, Footer, Contact, Header},
        data() {
            return {
                service: {},
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get("/api/v1/service/" + to.params.id).then(response => {
                next(vm => vm.setService(response.data))
            })
        },
        mounted() {
            const PatientsPlugin = new window.PatientsPlugin;
            PatientsPlugin.init({
                app: 'https://mydr.pl/patients_plugin',
                plugin: 'https://mydr.pl/static',
            });
        },
        methods: {
            setService (service) {
                this.service = service
            }
        }
    }
</script>
